import { createStore } from 'vuex'

export default createStore({
  state: {
    // 用户信息
    userInfo: {
      a: 1
    },
    userRoutesInfo: {},
  },
  getters: {
  },
  mutations: {
    // 存储用户状态
    SET_USERINFO (state, value) {
      state.userInfo = value
    },
    // 存储路由信息
    SET_USERROUTE (state, value) {
      state.userRoutesInfo = value
    },
  },
  actions: {
  },
  modules: {
  }
})
