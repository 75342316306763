import { ElLoading } from 'element-plus'

export const PHONE_NUMBER = '0575-87010553'
export const LOADING = (opt) => ElLoading.service({ lock: true, background: 'rgba(255, 255, 255, 0.9)', ...opt})

export const WORK_STATUS_ENUM = {
  '离职,正在找工作': { text: '离职,正在找工作' },
  '在职,急寻新工作': { text: '在职,急寻新工作' },
  '在职,看看新机会': { text: '在职,看看新机会' },
}

export const SEX_ENUM = {
  '男': { text: '男' },
  '女': { text: '女' }
}

// 学历
export const QUALIFICATIONS_ENUM = {
  '小学': { text: '小学' },
  '初中': { text: '初中' },
  '中专': { text: '中专' },
  '高中': { text: '高中' },
  '职高': { text: '职高' },
  '大专': { text: '大专' },
  '本科': { text: '本科' },
  '研究生': { text: '研究生' },
  '博士': { text: '博士' },
}

export const POLITICS_ENUM = {
  '中共党员': { text: '中共党员' },
  '中共预备党员': { text: '中共预备党员' },
  '共青团员': { text: '共青团员' },
  '群众': { text: '群众' },
  '民主党派': { text: '民主党派' },
  '其他': { text: '其他' },
}

export const MARITAL_OPTIONS = {
  '未婚': { text: '未婚' },
  '已婚': { text: '已婚' },
  '离异': { text: '离异' },
  '丧偶': { text: '丧偶' }
}

export const WORK_NATURE = {
  '全职': { text: '全职' },
  '兼职': { text: '兼职' }
}

export const SALARY_TYPE_ENUM = {
  '日薪': { text: "日薪" },
  '月薪':  { text: "月薪" },
}

export const SALARY_DAY_ENUM = {
  '50以内': { text: '50以内' },
  '50-100': { text: '50-100' },
  '100-200': { text: '100-200' },
  '200-300': { text: '200-300' },
  '300-400': { text: '300-400' },
  '400-500': { text: '400-500' },
  '500以上': { text: '500以上' },
}

export const SALARY_ENUM = {
  '2000-3000': { text: '2000-3000' },
  '3000-4000': { text: '3000-4000' },
  '4000-5000': { text: '4000-5000' },
  '5000-6000': { text: '5000-6000' },
  '6000-7000': { text: '6000-7000' },
  '7000-8000': { text: '7000-8000' },
  '8000-9000': { text: '8000-9000' },
  '9000-10000': { text: '9000-10000' },
  '10000-12000': { text: '10000-12000' },
  '12000-14000': { text: '12000-14000' },
  '14000-16000': { text: '14000-16000' },
  '16000-18000': { text: '16000-18000' },
  '20000-9999999': { text: '20000以上' },
}


export const JOB_EXP = {
  '1-3年': { text: '1-3年' },
  '3-5年': { text: '3-5年' },
  '5-10年': { text: '5-10年' },
  '10年以上': { text: '10年以上' },
}


export const POST_AGE_START_ENUM = {
  20: { text: 20 },
  30: { text: 30 },
  40: { text: 40 },
  50: { text: 50 },
}

export const POST_AGE_END_ENUM = {
  30: { text: 30 },
  40: { text: 40 },
  50: { text: 50 },
  60: { text: 60 },
}

export const GOOD_POINTS = {
  '年终奖': { text: '年终奖' },
  '饭补': { text: '饭补' },
  '五险': { text: '五险' },
  '包食宿': { text: '包食宿' },
  '五险一金': { text: '五险一金' },
  '市区': { text: '市区' },
  '法定节假日': { text: '法定节假日' },
  '团队聚餐': { text: '团队聚餐' },
}

export const COMPANY_TYPE = {
  '私营/民营企业': { text: '私营/民营企业' },
  '国有企业': { text: '国有企业' },
  '国内上市公司': { text: '国内上市公司' },
  '政府机关/非盈利机构': { text: '政府机关/非盈利机构' },
  '事业单位': { text: '事业单位' },
  '外商独资企业': { text: '外商独资企业' },
  '中外合资/合资/合作': { text: '中外合资/合资/合作' },
  '跨国企业': { text: '跨国企业' },
  '门店/个体经营户': { text: '门店/个体经营户' },
  '其他': { text: '其他' },
}

export const WORK_PARTICIPATION_ENUM = {
  '无经验': { text: '无经验' },
  '1-3年': { text: '1-3年' },
  '3-5年': { text: '3-5年' },
  '5-10年': { text: '5-10年' },
  '10年以上': { text: '10年以上' },
}

export const HIGHT_LIGHT = {
  '会开车': { text: '会开车' },
  '认真上进': { text: '认真上进' },
  '友亲和力': { text: '友亲和力' },
  '有工作经验': { text: '有工作经验' },
}

export const AREA_ENUM = {
  '区域不限': { text: '区域不限' },
  '暨阳街道': { text: '暨阳街道' },
  '浣东街道': { text: '浣东街道' },
  '陶朱街道': { text: '陶朱街道' },
  '暨南街道': { text: '暨南街道' },
  '大唐街道': { text: '大唐街道' },
  '店口镇': { text: '店口镇' },
  '应店街镇': { text: '应店街镇' },
  '次坞镇': { text: '次坞镇' },
  '姚江镇': { text: '姚江镇' },
  '山下湖镇': { text: '山下湖镇' },
  '枫桥镇': { text: '枫桥镇' },
  '赵家镇': { text: '赵家镇' },
  '马剑镇': { text: '马剑镇' },
  '安华镇': { text: '安华镇' },
  '牌头镇': { text: '牌头镇' },
  '五泄镇': { text: '五泄镇' },
  '同山镇': { text: '同山镇' },
  '璜山镇': { text: '璜山镇' },
  '陈宅镇': { text: '陈宅镇' },
  '浬浦镇': { text: '浬浦镇' },
  '岭北镇': { text: '岭北镇' },
  '东白湖镇': { text: '东白湖镇' },
  '东和乡': { text: '东和乡' },
}

export const WELFARE = {
  '包食宿': { text: '包食宿' },
  '工资日结': { text: '工资日结' },
  '有车接送': { text: '有车接送' },
  '双休': { text: '双休' },
}

export const SPECIALTY = {
  '会开车': { text: '会开车' },
  '性格开朗': { text: '性格开朗' },
  '工作认真': { text: '工作认真' },
}

export const NEWS_TYPE = {
  '国企公开': '国企公开',
  '政府单位': '政府单位',
}

export const AREA_LIST = [
  {
    label: '暨阳街道',
    value: '暨阳街道'
  },
  {
    label: '陶朱街道',
    value: '陶朱街道'
  },
  {
    label: '浣东街道',
    value: '浣东街道'
  },
  {
    label: '店口镇',
    value: '店口镇'
  },
  {
    label: '暨南街道',
    value: '暨南街道'
  },
  {
    label: '大唐街道',
    value: '大唐街道'
  },
  {
    label: '山下湖镇',
    value: '山下湖镇'
  },
  {
    label: '姚江镇',
    value: '姚江镇'
  },
  {
    label: '次坞镇',
    value: '次坞镇'
  },
  {
    label: '枫桥镇',
    value: '枫桥镇'
  },
  {
    label: '安华镇',
    value: '安华镇'
  },
  {
    label: '牌头镇',
    value: '牌头镇'
  },
  {
    label: '其他',
    value: '其他'
  }
]

export const SALARY = [
  {
    label: '3k以下',
    value: {
      postSalaryStart: 0,
      postSalaryEnd: 3000
    }
  },
  {
    label: '3k-5k',
    value: {
      postSalaryStart: 3000,
      postSalaryEnd: 5000
    }
  },
  {
    label: '5k-10k',
    value: {
      postSalaryStart: 5000,
      postSalaryEnd: 10000
    }
  },
  {
    label: '10k-20k',
    value: {
      postSalaryStart: 10000,
      postSalaryEnd: 20000
    }
  },
  {
    label: '20k以上',
    value: {
      postSalaryStart: 20000,
      postSalaryEnd: 999999999
    }
  }
]

export const PUBLISH_TIME = [
  // {
  //   label: '不限',
  //   value: null
  // },
  {
    label: '一天以内',
    value: 1
  },
  {
    label: '三天以内',
    value: 3
  },
  {
    label: '一周以内',
    value: 7
  },
  {
    label: '一个月以内',
    value: 30
  },
]
