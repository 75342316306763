import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'

import zhCn from 'element-plus/dist/locale/zh-cn.mjs'
import './global.css'

const vueApp = createApp(App).use(store).use(router).use(ElementPlus,  {
  locale: zhCn,
})

router.isReady().then(() => vueApp.mount('#app'))

// window.PUBLIC_PATH = process.env.BASE_URL
// window.MANAGE_PATH = 'https://www.zhujirc.com/custom' // `${window.location.origin}/custom`

// window.MANAGE_PATH = `localhost:8000/custom` 