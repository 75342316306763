<template>
  <div class="layout">
    <div class="body">
      <router-view v-slot="{Component}">
        <component :is="Component" />
      </router-view >
    </div>
    <div class="page-footer" v-if="!hiddenFooter">
      <div class="page-footer-main">
        <div class="net-base-info">
          <div class="base-info-title">找工作招人才 就上诸暨人才交流网</div>
          <div class="base-info-text">诸暨市智联达信息技术服务有限公司·诸暨人才交流网</div>
          <div class="">
            <a class="alink" ref="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=33068102000154" target="_blank">浙公网安备 33068102000154号</a>
            <a class="alink" style="margin-left: 20px;" href="http://beian.miit.gov.cn" target="_blank">浙ICP备11020498号-3</a>
          </div>
        </div>
        <div class="qr-item">
          <div class="qr-item-item">
            <div class="qr-title">掌上诸暨APP</div>
            <img class="qr-image" :src="appQr" />
          </div>
          <div class="qr-item-item">
            <div class="qr-title">微信小程序</div>
            <img class="qr-image-1"  :src="gzhQr" />
          </div>
        </div>
        <div class="work-time">
          <div class="work-time-title">服务电话</div>
          <div class="phone-number">
            <span class="phone-icon"> <el-icon color="#409EFC" class="no-inherit"> <PhoneFilled /></el-icon></span>
            <span class="phone-number-text">{{ PHONE_NUMBER }}</span>
          </div>
          <div class="work-time-text">服务时间：周一至周六 8:30-17:00</div>
          <div class="work-time-text">客服QQ：601867081</div>
          <div class="work-time-text">投诉电话：0575-87020951</div>

        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { useRoute, useRouter } from 'vue-router'
import { ref, watch } from 'vue'
import appQr from '@/assets/appQRCode.png'
import gzhQr from '@/assets/miniQr.jpeg'
import { PhoneFilled } from '@element-plus/icons-vue'
import { PHONE_NUMBER } from '@/utils/constants'

const hiddenFooter = ref(false)

const route = useRoute()

watch(() => route.path,
  (newPath, oldPath) => {
    console.log(route.meta, 'routeroute')
    hiddenFooter.value = route.meta && route.meta.hiddenFooter

  },
  { immediate: true }
);

</script>
<style lang="less" scoped>
.page-body {
  // display: flex;
  // overflow: hidden;
  position: relative;
}

.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  transition: all 0s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(20px);
  opacity: 0;
}

.body {
  padding-bottom: 40px;

  min-width: 1200px;
}
.page-footer {
  padding: 40px;
  background-color: #fff;
}

.page-footer-main {
  margin: 0 auto;
  text-align: left;
  display: flex;
  // align-items: center;
  justify-content: space-between;
  width: 1180px;
}

.base-info-title {
  font-size: 22px;
  font-weight: 500;
  margin-bottom: 8px;
  margin-top: 4px;
  
}

:deep {
  :focus-visible {
    outline: none;
  }
}

.footer-line-2 {
  margin-top: 12px;
  line-height: 20px;
  color: #666;
}

.beian-icon {
  background-image: url(../assets/beian.jpeg);
  background-size: contain;
  width: 18px;
  height: 18px;
  display: inline-block;
  vertical-align: middle;
  margin-right: 4px;
  vertical-align: middle;
}

.net-base-info {
  padding-right: 20px;
}

.alink {
  display: inline-block;
  text-decoration: none;
  height: 20px;
  line-height: 20px;
  cursor: pointer;
  color: #666;
}

.base-info-text {
  color: #666;
  padding: 8px 0;
}
.alink:hover {
  color: #FF4000;
}

.qr-item {
  display: flex;
  text-align: center;
  font-size: 16px;
  padding-left: 40px;
}

.qr-image {
  width: 100px;
}

.qr-image-1 {
  width: 100px;
}

.qr-title {
  margin-bottom: 6px;
}

.work-time-title {
  font-size: 16px;
  margin-bottom: 12px;
}

.qr-item-item {
  margin-right: 18px;
}
.work-time {

}

.phone-number {
  color: #409EFC;
  margin-bottom: 8px;
}

.phone-icon {
  font-size: 16px;
  display: inline-block;
  border: 2px solid #409EFC;
  width: 26px;
  height: 26px;
  border-radius: 13px;
  padding-top: 2px;
  padding-left: 3px;
}

.phone-number-text {
  font-size: 22px;
  margin-left: 6px;
  font-weight: bolder;
}

.work-time-text {
  padding: 2px 0;
  color: #666;
}
</style>
